import { useEffect, useState } from "react";
import { IndexDefinition } from "../../models/indexdefinition";

interface FilterPillProps {
  pillname: string;
  data: IndexDefinition[];
  appliedFilters: string[];
  addCallback: (value: string, startFresh: boolean) => void;
  removeCallback: (value: string) => void;
  openFilters: string[];
  setOpenFilters: (openFilters: string[]) => void;
  allSelected: boolean;
  setAllSelected: (value: boolean) => void;
}

const FilterPill: React.FC<FilterPillProps> = ({
  pillname,
  data,
  appliedFilters,
  addCallback,
  removeCallback,
  openFilters,
  setOpenFilters,
  allSelected,
  setAllSelected,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [appliedLocalFilters, setAppliedLocalFilters] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const index = openFilters.indexOf(pillname, 0);
    if (index > -1) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [openFilters]);

  const addToAppliedLocalFilters = (value: string) => {
    const newFilters = [...appliedLocalFilters];

    const newValues = value.split(",");

    newValues.forEach((newValue) => {
      const index = newFilters.indexOf(newValue, 0);

      if (index < 0) {
        newFilters.push(newValue);
      }
    });

    setAppliedLocalFilters(newFilters);
    addCallback(value, allSelected);
    setAllSelected(false);
  };

  useEffect(() => {
    if (!allSelected) {
      setAppliedLocalFilters(findLocalFilters());
    } else {
      setAppliedLocalFilters([]);
    }
  }, [allSelected]);

  const findLocalFilters = () => {
    let returnFilters: string[] = [];

    data.forEach((element) => {
      const result = appliedFilters.filter(
        (filter) => filter === element.indexName
      );

      if (result.length > 0) {
        returnFilters.push(element.indexName);
      }
    });

    return returnFilters;
  };

  const removeFromAppliedLocalFilters = (value: string) => {
    const newFilters = [...appliedLocalFilters];
    const newValues = value.split(",");
    newValues.forEach((newValue) => {
      do {
        const index = newFilters.indexOf(newValue, 0);
        if (index > -1) {
          newFilters.splice(index, 1);
        }
      } while (newFilters.indexOf(newValue, 0) > -1);
    });
    setAppliedLocalFilters(newFilters);
    removeCallback(value);
  };

  const isAllChecked = (data: IndexDefinition[], appliedFilters: string[]) => {
    const dataLength = data.length;
    let matchLength = 0;

    data.forEach((element) => {
      const index = appliedFilters.indexOf(element.indexName, 0);
      if (index > -1) {
        matchLength = matchLength + 1;
      }
    });

    return dataLength === matchLength;
  };

  const changeVisibility = () => {
    setVisible(!visible);
    setSearchTerm("");

    if (!visible) {
      const newOpenFilters = [...openFilters];
      newOpenFilters.push(pillname);
      setOpenFilters([pillname]);
    }
  };

  return (
    <div
      className={
        !allSelected && appliedLocalFilters.length > 0
          ? "border border-blue rounded-full my-auto bg-blue"
          : "border border-blue rounded-full my-auto"
      }
    >
      <div
        className={
          !allSelected && appliedLocalFilters.length > 0
            ? "p-2 text-white"
            : "p-2"
        }
      >
        <button type="button" onClick={() => changeVisibility()}>
          {pillname}&nbsp;
          {appliedLocalFilters.length > 0
            ? "(" + appliedLocalFilters.length + ")"
            : ""}
          <div className="inline-block pl-1 pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current stroke-0"
              width="8"
              height="8"
              fill="currentColor"
              viewBox="0 0 8 4"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M0.666016 0.333252L3.99935 3.66659L7.33268 0.333252H0.666016Z"
              />
            </svg>
          </div>
        </button>
      </div>
      <div
        className={
          visible
            ? "mt-px absolute p-1 bg-white shadow-md flex flex-col gap-2 "
            : "mt-px absolute p-1 bg-white shadow-md flex flex-col gap-2 invisible"
        }
      >
        <div>
          <div className="border border-light-gray w-full flex flex-row">
            <div className="flex-none m-auto p-2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7559 11.2549H11.9659L11.6859 10.9849C12.6659 9.84488 13.2559 8.36488 13.2559 6.75488C13.2559 3.16488 10.3459 0.254883 6.75586 0.254883C3.16586 0.254883 0.255859 3.16488 0.255859 6.75488C0.255859 10.3449 3.16586 13.2549 6.75586 13.2549C8.36586 13.2549 9.84586 12.6649 10.9859 11.6849L11.2559 11.9649V12.7549L16.2559 17.7449L17.7459 16.2549L12.7559 11.2549ZM6.75586 11.2549C4.26586 11.2549 2.25586 9.24488 2.25586 6.75488C2.25586 4.26488 4.26586 2.25488 6.75586 2.25488C9.24586 2.25488 11.2559 4.26488 11.2559 6.75488C11.2559 9.24488 9.24586 11.2549 6.75586 11.2549Z"
                  fill="#1E252B"
                />
              </svg>
            </div>
            <div className="flex-1 m-auto">
              <input
                type="text"
                className="w-full h-full p-2"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              ></input>
            </div>
          </div>
        </div>

        {searchTerm.length < 1 && (
          <div className="flex flex-row gap-1">
            <div className="flex">
              <input
                type="checkbox"
                value={data.map((item) => item.indexName)}
                checked={isAllChecked(data, appliedLocalFilters)}
                onChange={(e) =>
                  e.target.checked
                    ? addToAppliedLocalFilters(e.target.value)
                    : removeFromAppliedLocalFilters(e.target.value)
                }
              ></input>
            </div>
            <div>All Options</div>
          </div>
        )}

        {data
          .filter((word) =>
            word.displayText.toLowerCase().startsWith(searchTerm)
          )
          .sort((a, b) => a.displayText.localeCompare(b.displayText))
          .map((item) => (
            <div className="flex flex-row gap-1" key={item.indexName}>
              <div className="flex">
                <input
                  type="checkbox"
                  value={item.indexName}
                  checked={appliedLocalFilters.indexOf(item.indexName, 0) > -1}
                  onChange={(e) =>
                    e.target.checked
                      ? addToAppliedLocalFilters(e.target.value)
                      : removeFromAppliedLocalFilters(e.target.value)
                  }
                ></input>
              </div>
              <div>{item.displayText}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FilterPill;
