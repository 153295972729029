import { useState } from "react";
import { FeedbackType } from "../../enums/FeedbackTypes";

const FeedbackOptions = {
  Didntanswerthequestion: "Didn't answer the question",
  Notfactuallycorrect: "Not factually correct",
  Missingdetailsorkeyinformation: "Missing details or key information",
  Redundantinformation: "Redundant information",
  Other: "Other",
};

interface FeedbackBoxProps {
  setFeedbackBoxEnabled: (value: boolean) => void;
  callback: (
    feedbackType: FeedbackType,
    feedback: string,
    feedbackMessage: string
  ) => void;
}

const FeedbackBox: React.FC<FeedbackBoxProps> = ({
  setFeedbackBoxEnabled,
  callback,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const handleUserChange = (value: string) => {
    setInputValue(value);
  };

  const submitFeedback = () => {
    callback(FeedbackType.Down, feedback, inputValue);
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#8D8D8D] bg-opacity-25 z-10">
      <div className="flex justify-center items-center w-full">
        <div className="bg-white p-10 min-w-[600px]">
          <div className="flex flex-col gap-2">
            <div>
              <h5>What went wrong?</h5>
            </div>
            {Object.entries(FeedbackOptions).map((option) => (
              <div key={option[0]}>
                <input
                  type="radio"
                  name="feedbackoption"
                  value={option[1]}
                  className="mr-2"
                  onChange={(e) => setFeedback(e.target.value)}
                ></input>
                {option[1]}
              </div>
            ))}
            <div>Additional Comments (optional)</div>
            <div>
              <textarea
                className="w-full resize-none text-charcoal-dark border border-light-gray-40 p-4"
                rows={5}
                placeholder="Please do not provide any personal information."
                value={inputValue}
                onChange={(e) => handleUserChange(e.target.value)}
              ></textarea>
            </div>
            <div>
              <div className="flex flex-row gap-2 justify-end">
                <div>
                  <button
                    type="button"
                    className="py-2 px-4"
                    onClick={() => setFeedbackBoxEnabled(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    disabled={feedback.length > 0 ? false : true}
                    type="button"
                    className="bg-light-gray-40 text-white py-2 px-4"
                    onClick={() => submitFeedback()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackBox;
