import { useEffect, useRef, useState } from "react";
import { ImageSearchResponse } from "../models/imagesearchresponse";
import { msalInstance as msalInstanceAAD } from "../components/authenticator/azureAAD/Authenticator";
import { mockImageResponse1 } from "../data/mockimageresponse1";

function useGetImageSearchResponse(
  message: string,
  indexes: string[],
  questionId: string
) {
  const [imageData, setImageData] = useState<ImageSearchResponse | null>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageLoadingError, setImageLoadingError] = useState<string | null>(
    null
  );

  const imageAbortControllerRef = useRef<AbortController>(
    new AbortController()
  );

  useEffect(() => {
    // console.log("Image Response Question ID: ", questionId);

    if (
      message &&
      message.length > 0 &&
      indexes &&
      indexes.length > 0 &&
      questionId &&
      questionId.length > 0
    ) {
      setImageLoading(true);
      setImageLoadingError(null);

      // setImageData(mockImageResponse1);
      // console.log(mockImageResponse1);
      // setImageLoading(false);

      // create the api body
      const body =
        `{
        "content": ` +
        JSON.stringify(message.replace(/\r\n|\n|\r/gi, " ")) +
        `,
        "indexes": "` +
        indexes.toString() +
        `"}`;

      // get the auth tokenRequest
      // const accounts = msalInstanceAAD.getAllAccounts();
      // const authToken = accounts[0]?.idToken;

      // Perform a search for images
      //
      fetch(process.env.REACT_APP_CHAT_IMAGE_SEARCH_URL!, {
        method: "POST",
        signal: imageAbortControllerRef.current.signal,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body,
      })
        .then(async (resp: any) => {
          const response = await resp.json();
          setImageLoading(false);
          setImageData(response);
        })
        .catch((err: Error) => {
          console.log("Image retrieval error");
          console.log(err);
          if (!imageAbortControllerRef.current.signal.aborted) {
            setImageLoadingError(err.message);
          } else {
            setImageLoadingError("The response has been stopped.")
          }
          setImageLoading(false);
        });
      //
      // end search
    }
  }, [message, indexes, questionId]);

  return {
    imageData,
    imageLoading,
    imageAbortControllerRef,
    imageLoadingError,
  };
}

export default useGetImageSearchResponse;
