import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CommonLayout from "./layouts/commonlayout";
import { Home, Chat, TestPage } from "./pages";

import ChatContextProvider from "./contexts/ChatContext/ChatContextProvider";
import { Authenticator } from "./components";
import Citation from "./pages/citation/citation";

const router = createBrowserRouter([
  {
    path: "/",
    Component: CommonLayout,
    children: [
      {
        path: "/",
        Component: Home,
      },
      {
        path: "chat",
        Component: Chat,
      },
      {
        path: "citation",
        Component: Citation,
      },
    ],
  },
  {
    path: "/test2",
    Component: TestPage,
  },
]);

const App = () => {
  return (
    <ChatContextProvider>
      <Authenticator>
        <RouterProvider router={router} />
      </Authenticator>
    </ChatContextProvider>
  );
};

export default App;
