import { IndexDefinition } from "../models/indexdefinition";

export const conference_proceedings: IndexDefinition[] = [
  {
    displayText: "Strategic Vs. Tactical Mining",
    indexName: "conference_strategic_vs_tactical_mining",
  },
  {
    displayText: "Rasim",
    indexName: "conference_rasim",
  },
  {
    displayText: "Massmin",
    indexName: "conference_massmin",
  },
  {
    displayText: "Ground Support in Mining and UG Construction",
    indexName: "conference_ground_support_in_mining_and_ug_construction",
  },
  {
    displayText: "Ground Support",
    indexName: "conference_ground_support",
  },
  {
    displayText: "Deepmining",
    indexName: "conference_deepmining",
  },
  {
    displayText: "Caving",
    indexName: "conference_02_caving",
  },
];
