interface ErrorCardProps {
  title: string;
  error: string | undefined;
}

const ErrorCard: React.FC<ErrorCardProps> = ({ title, error }) => {
  return (
    <div className="flex w-full pt-40">
      <div className="flex flex-col text-center gap-2">
        <div className="m-auto">
          <svg
            width="75"
            height="75"
            viewBox="0 0 75 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7873 0.768743C13.4708 0.774993 12.1605 0.898899 10.8664 1.14937C27.5695 11.2036 40.0419 24.6431 49.8852 40.1875C50.2798 31.7875 46.9055 23.1359 41.5206 16.0859C39.318 16.9137 36.6023 16.2019 34.7287 14.3281C32.8569 12.4562 32.1416 9.74265 32.9659 7.54093C27.3836 3.3289 21.0117 0.737493 14.7873 0.768743ZM74.2502 3.95702L63.1664 16.0859L67.8633 35.7052L59.2552 37.077L56.2228 49.6748L67.7072 55.788L61.2619 64.5281L65.8959 71.4083L52.0238 67.0919L54.6019 60.2412L42.6195 54.9531L44.6702 41.7402L27.2678 50.8222L26.6084 63.0488L9.07406 58.7275L3.87875 75.0019H74.25L74.2502 3.95702ZM37.8048 7.17499C37.7442 7.17656 37.6836 7.17937 37.6242 7.18437C37.0548 7.23515 36.5489 7.44937 36.1886 7.80937C35.2281 8.77031 35.2908 10.7594 36.7941 12.2625C38.2975 13.7661 40.2867 13.8287 41.2472 12.8681C42.2078 11.9075 42.1452 9.91843 40.6417 8.41499C39.7609 7.53421 38.7144 7.15015 37.8048 7.17499ZM27.7023 17.9364L0.949219 44.6991C1.61687 46.5625 2.67219 47.9881 4.89453 48.7031L31.682 21.9062C30.4639 20.5172 29.1339 19.1978 27.7023 17.9364ZM52.5117 19.7528L53.967 32.985L58.2541 19.7527H52.5119L52.5117 19.7528ZM31.882 31.4666L28.7327 37.0817L43.215 38.2341L31.882 31.4666Z"
              fill="#FF372F"
            />
          </svg>
        </div>
        <div>
          <h5 className="font-semibold">{title}</h5>
        </div>
        <div>
          {error
            ? error
            : "Our digital geologists are scratching their heads over this one. It appears the quartz vein we were following led straight into the void. In the meantime, feel free to explore other digital caverns or try a fresh search."}
        </div>
      </div>
    </div>
  );
};

export default ErrorCard;
