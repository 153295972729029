import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { AIResponse } from "../models/airesponse";

function useGetAIResponse<AIResponse>(message: string) {
  const [data, setData] = useState<AIResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const controller = new AbortController();

  useEffect(() => {
    if (message.length > 0) {
      setLoading(true);
      setError(null);

      console.log("axios go");

      axios
        .post(
          "https://apim-aimining-dev-26844.azure-api.net/ChatWithDataCompletion",
          message,
          {
            signal: controller.signal,
            headers: {
              "Content-Type": "text/plain",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((resp: AxiosResponse) => {
          console.log("axios response");
          const response = resp.data;
          console.log(response);
          setData(response);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [message]);

  return { data, loading, error };
}

export default useGetAIResponse;
