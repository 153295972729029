interface StopRespondingButtonProps {
  abortControllerRef: React.MutableRefObject<AbortController>[];
}

const StopRespondingButton: React.FC<StopRespondingButtonProps> = ({
  abortControllerRef,
}) => {
  return (
    <button
      type="button"
      className="border border-b p-3 flex gap-2 m-auto"
      onClick={() => {
        // abortControllerRef.current.abort();
        abortControllerRef.forEach((item) => item.current.abort());
      }}
    >
      <div className="flex m-auto">
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.49935 0.666687C3.89935 0.666687 0.166016 4.40002 0.166016 9.00002C0.166016 13.6 3.89935 17.3334 8.49935 17.3334C13.0993 17.3334 16.8327 13.6 16.8327 9.00002C16.8327 4.40002 13.0993 0.666687 8.49935 0.666687ZM8.49935 15.6667C4.81602 15.6667 1.83268 12.6834 1.83268 9.00002C1.83268 5.31669 4.81602 2.33335 8.49935 2.33335C12.1827 2.33335 15.166 5.31669 15.166 9.00002C15.166 12.6834 12.1827 15.6667 8.49935 15.6667ZM11.8327 12.3334H5.16602V5.66669H11.8327V12.3334Z"
            fill="#3D5F7B"
          />
        </svg>
      </div>
      <div className="flex m-auto">Stop Responding</div>
    </button>
  );
};

export default StopRespondingButton;
