export const knowledge_base = [
  {
    displayText: "Seismicity",
    indexName: "knowledge_seismicity_base_knowledge",
  },
  {
    displayText: "Rock Engineering",
    indexName: "knowledge_base_rock_engineering",
  },
  {
    displayText: "Mining Knowledge",
    indexName: "knowledge_base_mining_knowledge",
  },
  {
    displayText: "High Stress Rock Mechanics",
    indexName: "knowledge_base_high_stress_rock_mechanics",
  },
  {
    displayText: "Caving Knowledge",
    indexName: "knowledge_base_caving_knowledge",
  },
];
