import { CitationType } from "../../enums/CitationTypes";
import { ImageSearchResponse } from "../../models/imagesearchresponse";
import CitationCard from "../citationcard/citationcard";

interface ImageListProps {
  images?: ImageSearchResponse | null;
  callback: (value: string) => void;
}

const ImageList: React.FC<ImageListProps> = ({ images, callback }) => {
  return (
    <>
      <div>
        <h6 className="text-red font-semibold flex py-4">
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="my-auto mr-2"
          >
            <path
              d="M15.667 1.99998H9.00033L7.33366 0.333313H2.33366C1.41699 0.333313 0.675326 1.08331 0.675326 1.99998L0.666992 12C0.666992 12.9166 1.41699 13.6666 2.33366 13.6666H15.667C16.5837 13.6666 17.3337 12.9166 17.3337 12V3.66665C17.3337 2.74998 16.5837 1.99998 15.667 1.99998ZM15.667 12H2.33366V1.99998H6.64199L8.30866 3.66665H15.667V12ZM14.0003 6.99998H4.00033V5.33331H14.0003V6.99998ZM10.667 10.3333H4.00033V8.66665H10.667V10.3333Z"
              fill="#FF372F"
            />
          </svg>
          Images
        </h6>
      </div>
      <div className="flex gap-4 flex-wrap">
        {images && images.length > 0 && images.map((item, i) => (
          <CitationCard
            citationType={CitationType.Image}
            citation={item[1].location}
            docPath={item[1].doc_path}
            displayText={item[1].location}
            index={i.toString()}
            callback={callback}
          />
        ))}
      </div>
    </>
  );
};

export default ImageList;
