import { useEffect, useState } from "react";
import { msalInstance } from "../components/authenticator/azureAAD/Authenticator";
import { AIChatResponseHistory } from "../models/aichatresponsehistory";
import { ChatContextType } from "../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../contexts/ChatContext/ChatContextProvider";
import React from "react";

function useGetPinnedChats(chatHistoryUpdateTimestamp: string) {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [pinnedChatData, setPinnedChatData] = useState<
    AIChatResponseHistory[] | null
  >(null);

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();
    const userId = accounts[0].username;

    const body =
      `{
        "user_id": "` +
      userId +
      `"
    }`;

    fetch(process.env.REACT_APP_CHAT_GET_PINS_URL!, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body,
    })
      .then(async (resp: any) => {
        const response = await resp.json();
        // console.log("db response");
        // console.log(response.chat_messages);

        setPinnedChatData(response.chat_messages);
      })
      .catch((err: Error) => {
        console.log("Pinnet Chats retrieval error");
        console.log(err);
      });
  }, [chatHistoryUpdateTimestamp]);

  return { pinnedChatData };
}

export default useGetPinnedChats;
