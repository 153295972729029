import { InteractiveBrowserCredential } from "@azure/identity";
import { BlobServiceClient } from "@azure/storage-blob";
import { useEffect, useState } from "react";

interface DocumentViewerProps {
  blobName: string;
}

interface DownloadedBlobRecord {
  blobName: string;
  downloadURL: string;
}

const isImage = (filename: string) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"];
  const extension = filename?.split("?")[0].split(".").pop()?.toLowerCase();
  return imageExtensions.includes(extension!);
};

const DocumentViewer: React.FC<DocumentViewerProps> = ({ blobName }) => {
  const [documentUrl, setDocumentUrl] = useState<string>("");
  const [downloadedBlobs, setDownloadedBlobs] = useState<
    DownloadedBlobRecord[]
  >([]);

  useEffect(() => {
    const getDocumentToShow = async (
      storageAccountName: string,
      containerName: string,
      blobPath: string,
      blobName: string
    ) => {
      const existingDownloadedBlobsRecord = downloadedBlobs.filter(
        (elem) => elem.blobName === blobName
      );

      if (existingDownloadedBlobsRecord.length < 1) {
        const signInOptions = {
          clientId: process.env.REACT_APP_AZURE_AAD_APP_CLIENT_ID,
          tenantId: process.env.REACT_APP_AZURE_AAD_APP_TENANT_ID,
        };

        const blobServiceClient = new BlobServiceClient(
          "https://" + storageAccountName + ".blob.core.windows.net/",
          new InteractiveBrowserCredential(signInOptions)
        );

        const containerClient =
          blobServiceClient.getContainerClient(containerName);

        const blobClient = containerClient.getBlobClient(
          decodeURI(blobPath + blobName)
        );

        let downloaded = await blobClient.download();

        const blob = (await downloaded.blobBody) as Blob;

        const downloadURL = URL.createObjectURL(blob);

        if (downloadURL !== documentUrl) {
          setDocumentUrl(downloadURL);
        }

        const newDownloadedBlobs = [...downloadedBlobs];
        newDownloadedBlobs.push({ blobName, downloadURL });
        setDownloadedBlobs(newDownloadedBlobs);
      } else {
        setDocumentUrl(existingDownloadedBlobsRecord[0].downloadURL);
      }
    };

    if (blobName && blobName.length > 0) {
      getDocumentToShow(
        "staiminingdev26844",
        isImage(blobName) ? "doc-extracts" : "llmdamm",
        isImage(blobName) ? "Images/" : "",
        blobName
      );
    }
  }, [blobName]);

  return isImage(blobName) ? (
    <img src={documentUrl} className="w-full h-full" />
  ) : (
    <embed src={documentUrl} type="application/pdf" className="w-full h-full" />
  );
};

export default DocumentViewer;
