import React from "react";

const Header: React.FC = () => {
  return (
    <div className="flex py-6">
      <div className="flex-none w-24">
        <a title="WSP" href="/">
          <svg
            aria-labelledby="title"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-red"
            viewBox="0 0 57.372 27.285"
          >
            <title id="title" lang="en">
              WSP
            </title>
            <defs></defs>
            <path
              d="M85.453,25.95a11.223,11.223,0,0,0-2.613-7.373h4.3a12.35,12.35,0,0,1,2.283,7.373v.011a12.358,12.358,0,0,1-2.291,7.385h-4.3a11.218,11.218,0,0,0,2.621-7.385Z"
              transform="translate(-32.048 -18.577)"
            ></path>
            <path
              d="M3.968,0H0L5.445,14.766H7.554l.929-2.52Z"
              transform="translate(0 0)"
            ></path>
            <path
              d="M17.419,0H13.451L18.9,14.766H21l.929-2.52Z"
              transform="translate(-2.807 0)"
            ></path>
            <path
              d="M55.761,27.285V0h-3.8V27.285Z"
              transform="translate(-10.843 0)"
            ></path>
            <path
              d="M47.912,20.767a6.507,6.507,0,0,1,.512-2.19H51.9a3.188,3.188,0,0,0-.252,2.429,3.638,3.638,0,0,0,1.622,1.924,23.1,23.1,0,0,0,2.871,1.331,11.618,11.618,0,0,1,2.8,1.673A5.551,5.551,0,0,1,61,29.842c.015.192.021.385.021.579a6.516,6.516,0,0,1-.61,2.92H56.846a3.977,3.977,0,0,0,.577-2.16c-.125-1.86-2.4-2.878-4.3-3.723-.29-.134-.575-.255-.847-.383a10.864,10.864,0,0,1-2.37-1.433,5.031,5.031,0,0,1-1.641-2.124,6.146,6.146,0,0,1-.372-2.485C47.9,20.944,47.9,20.855,47.912,20.767Z"
              transform="translate(-24.756 -18.577)"
            ></path>
          </svg>
        </a>
      </div>
      <div className="flex-1 text-right"></div>
    </div>
  );
};

export default Header;
