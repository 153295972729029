import { PromptSnippet } from "../models/promptsnippet";

export const promptSnippets: PromptSnippet[] = [
  {
    text: "Find projects that...",
    value: "Find projects that ",
  },
  {
    text: "Respond to an RFP that...",
    value: "Respond to an RFP that ",
  },
  {
    text: "Search recent projects for...",
    value: "Search recent projects for ",
  },
];

export const promptGroup1: PromptSnippet[] = [
  {
    text: "How does the Q system account for high mining induced stress?",
    value: "How does the Q system account for high mining induced stress?",
  },
  {
    text: "What is shakedown and how can it be prevented?",
    value: "What is shakedown and how can it be prevented?",
  },
  {
    text: "What is the difference between a strainburst and a shear rupture?",
    value: "What is the difference between a strainburst and a shear rupture?",
  },
  {
    text: "How does rockburst damage impact production from cave mines?",
    value: "How does rockburst damage impact production from cave mines?",
  },
  {
    text: "What is the difference between strategic and tactical controls for rockbursting?",
    value: "What is the difference between strategic and tactical controls for rockbursting?",
  },
  {
    text: "How can brittle rock mass behaviour be captured in continuum models?",
    value: "How can brittle rock mass behaviour be captured in continuum models?",
  },
];

export const promptGroup2: PromptSnippet[] = [
  {
    text: "How to log veined rock core?",
    value: "How to log veined rock core?",
  },
  {
    text: "How to estimate corrosion rates for steel ground support in mines?",
    value: "How to estimate corrosion rates for steel ground support in mines?",
  },
  {
    text: "How to calculate the maximum stress at the boundary of a tunnel?",
    value: "How to calculate the maximum stress at the boundary of a tunnel?",
  },
];

export const promptGroup3: PromptSnippet[] = [
  {
    text: "What are key challenges in the DMLZ at Grasberg?",
    value: "What are key challenges in the DMLZ at Grasberg?",
  },
  {
    text: "What are the common vein types encountered at Red Chris and their implications for caving?",
    value: "What are the common vein types encountered at Red Chris and their implications for caving?",
  },
  {
    text: "What are possible challenges for caving with the silicic cap domain at the Hu'u project?",
    value: "What are possible challenges for caving with the silicic cap domain at the Hu'u project?",
  },
];
