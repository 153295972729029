import { useEffect, useState } from "react";
import { ChatContext } from "../../contexts/ChatContext/ChatContextProvider";
import { FilterScope } from "../../enums/FilterScope";
import { ChatContextType } from "../../contexts/ChatContext/ChatContextType";
import React from "react";

interface ImageFilterPillProps {
  pillname: string;
  openFilters: string[];
  setOpenFilters: (openFilters: string[]) => void;
  saveCallback: (scope: FilterScope) => void;
}

const filterOptions = [FilterScope.DocumentsAndImages, FilterScope.Documents];

const ImageFilterPill: React.FC<ImageFilterPillProps> = ({
  pillname,
  openFilters,
  setOpenFilters,
  saveCallback,
}) => {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [visible, setVisible] = useState<boolean>(false);
  const [filterScopeText, setFilterScopeText] = useState<FilterScope>(
    chatContext!.filterScope
  );

  useEffect(() => {
    const index = openFilters.indexOf(pillname, 0);
    if (index > -1) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [openFilters, pillname]);

  const changeVisibility = () => {
    setVisible(!visible);

    if (!visible) {
      setOpenFilters([pillname]);
    }
  };

  const changeFilterScope = (scope: FilterScope) => {
    setFilterScopeText(scope);
    saveCallback(scope);
    setVisible(false);
    // setOpenFilters([]);
  };

  return (
    <>
      <div className="my-auto">Apply to:</div>
      <div className="border border-blue rounded-full my-auto">
        <div className="p-2">
          <button type="button" onClick={() => changeVisibility()}>
            {filterScopeText}
            <div className="inline-block pl-1 pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current stroke-0"
                width="8"
                height="8"
                fill="currentColor"
                viewBox="0 0 8 4"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M0.666016 0.333252L3.99935 3.66659L7.33268 0.333252H0.666016Z"
                />
              </svg>
            </div>
          </button>
        </div>
        <div
          className={
            visible
              ? "mt-px absolute p-1 bg-white shadow-md flex flex-col gap-2 "
              : "mt-px absolute p-1 bg-white shadow-md flex flex-col gap-2 invisible"
          }
        >
          <div className="flex flex-col gap-1">
            {filterOptions.map((option) => (
              <div className="flex" key={option}>
                <button
                  type="button"
                  className="w-full text-start"
                  onClick={() => changeFilterScope(option)}
                >
                  {option}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageFilterPill;
