import { useMsal } from "@azure/msal-react";

const AADSignoutButton = () => {
  const { accounts, instance } = useMsal();
  const homeAccountId = accounts[0]?.homeAccountId;
  const logoutRequest = {
    account: instance.getAccountByHomeId(homeAccountId),
  };

  const onSignout = async () => {
    try {
      await instance.logoutRedirect(logoutRequest);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <button
      type="button"
      className="bg-blue text-white w-full py-2"
      onClick={onSignout}
    >
      Log Out
    </button>
  );
};

export default AADSignoutButton;
