import { IndexDefinition } from "../models/indexdefinition";

export const base: IndexDefinition[] = [
  {
    displayText: "Strategic Vs. Tactical Mining",
    indexName: "conference_strategic_vs_tactical_mining",
    folderName: "006_Conferences",
  },
  {
    displayText: "Rasim",
    indexName: "conference_rasim",
    folderName: "006_Conferences",
  },
  {
    displayText: "Massmin",
    indexName: "conference_massmin",
    folderName: "006_Conferences",
  },
  {
    displayText: "Ground Support in Mining and UG Construction",
    indexName: "conference_ground_support_in_mining_and_ug_construction",
    folderName: "006_Conferences",
  },
  {
    displayText: "Ground Support",
    indexName: "conference_ground_support",
    folderName: "006_Conferences",
  },
  {
    displayText: "Deepmining",
    indexName: "conference_deepmining",
    folderName: "006_Conferences",
  },
  {
    displayText: "Caving",
    indexName: "conference_02_caving",
    folderName: "006_Conferences",
  },
  {
    displayText: "Twin Metals",
    indexName: "mine_twin_metals",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Tamarack",
    indexName: "mine_tamarack",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Red Chris",
    indexName: "mine_red_chris",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Oyu Tolgoi",
    indexName: "mine_oyu_tolgoi",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "North Mine",
    indexName: "mine_north_mine",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Merdeka Copper",
    indexName: "mine_merdeka_copper",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Lkab",
    indexName: "mine_lkab",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Kazinc",
    indexName: "mine_kazinc",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Jwaneng",
    indexName: "mine_jwaneng",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Hu'u",
    indexName: "mine_hu_u",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Horne 5",
    indexName: "mine_horne_5",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Holt",
    indexName: "mine_holt",
    folderName: "H007_WSP Deliverablesolt",
  },
  {
    displayText: "Grasberg",
    indexName: "mine_grasberg",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Garson",
    indexName: "mine_garson",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Erg",
    indexName: "mine_erg",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "East Malartic",
    indexName: "mine_east_malartic",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Eagle East",
    indexName: "mine_eagle_east",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Creighton",
    indexName: "mine_creighton",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Coleman",
    indexName: "mine_coleman",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Codelco Chuquicamata",
    indexName: "mine_codelco_chuquicamata",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Carrapateena",
    indexName: "mine_carrapateena",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Caraiba",
    indexName: "mine_caraiba",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Cadia",
    indexName: "mine_cadia",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Manuals",
    indexName: "manuals",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Papers",
    indexName: "papers",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Guidance Documents",
    indexName: "guidance_documents",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Hermosa",
    indexName: "mine_hermosa",
    folderName: "007_WSP Deliverables",
  },  
  {
    displayText: "Seismicity",
    indexName: "knowledge_seismicity_base_knowledge",
    folderName: "007_WSP Deliverables",
  },
  {
    displayText: "Rock Engineering",
    indexName: "knowledge_base_rock_engineering",
    folderName: "001_Base Rock Engineering",
  },
  {
    displayText: "Mining Knowledge",
    indexName: "knowledge_base_mining_knowledge",
    folderName: "004_Base Mining Knowledge",
  },
  {
    displayText: "High Stress Rock Mechanics",
    indexName: "knowledge_base_high_stress_rock_mechanics",
    folderName: "002_Base High Stress Rock Mechanics",
  },
  {
    displayText: "Caving Knowledge",
    indexName: "knowledge_base_caving_knowledge",
    folderName: "005_Base Caving Knowledge",
  },
];
