import React, { RefAttributes, useEffect, useRef, useState } from "react";
import {
  WSPRule,
  ChatQuestionCard,
  ChatQuestionDock,
  StopRespondingButton,
} from "../../components";
import useGetAIChatResponse from "../../hooks/getAIChatResponse";
import { AIChatResponse } from "../../models/aichatresponse";
import { ChatContextType } from "../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../contexts/ChatContext/ChatContextProvider";
import { useNavigate } from "react-router-dom";
import loadingimage from "../../assets/images/Loading.gif";
import { v4 as uuidv4 } from "uuid";
import CSS from "csstype";

import DocumentViewer from "../../components/documentviewer/documentviewer";
import useGetImageSearchResponse from "../../hooks/getImageSearchResponse";
import { FilterScope } from "../../enums/FilterScope";
import { base } from "../../data";
import { AIChatResponseHistory } from "../../models/aichatresponsehistory";
import { FeedbackType } from "../../enums/FeedbackTypes";
import ErrorCard from "../../components/errorcard/errorcard";
import submitChatFeedback from "../../services/submitChatFeedback";
import { PinnedChat } from "../../models/pinnedchat";
import submitChatHistory from "../../services/submitChatHistory";

const allIndexes = base.map((item) => item.indexName);

const chatSuggestions: string[] = [
  "Tell me more.",
  "Give me a shorter summary.",
  "What are things to watch out for in a similar project?",
];

const Chat = () => {
  const navigate = useNavigate();
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [questionId, setQuestionId] = useState<string>("");
  // chat response
  const {
    data,
    loading,
    abortControllerRef,
    streaming,
    streamResponse,
    error,
  } = useGetAIChatResponse(
    chatContext ? chatContext?.chat.question : "",
    chatContext ? chatContext?.indexes : [],
    chatContext ? chatContext?.sessionId : uuidv4().replaceAll("-", ""),
    questionId
  );
  // image search
  const {
    imageData,
    imageLoading,
    imageAbortControllerRef,
    imageLoadingError,
  } = useGetImageSearchResponse(
    chatContext ? chatContext?.chat.question : "",
    chatContext
      ? chatContext.filterScope === FilterScope.DocumentsAndImages
        ? chatContext?.indexes
        : allIndexes
      : [],
    questionId
  );

  const latestAnswerRef = useRef<null | HTMLDivElement>(null);
  const [documentToShow, setDocumentToShow] = useState<string>("");

  const [dataToDisplay, setDataToDisplay] = useState<AIChatResponseHistory[]>(
    []
  );

  // generate a unique question id
  useEffect(() => {
    setQuestionId(uuidv4().replaceAll("-", ""));
  }, []);

  // save data to history
  useEffect(() => {
    if (data && imageData) {
      let newHistoryItem: AIChatResponseHistory = {
        questionId: questionId,
        sessionId: chatContext?.sessionId,
        question: data.question,
        output: data.output,
        indexes: chatContext ? chatContext?.indexes : [],
        pin: false,
        images: imageData,
        feedbackType: FeedbackType.None,
      } as AIChatResponseHistory;
      // console.log("Adding new history item");
      // console.log(newHistoryItem);
      chatContext?.addToChatHistory(newHistoryItem);
    }
  }, [data, imageData]);

  useEffect(() => {
    if (chatContext && chatContext.chatHistory) {
      setDataToDisplay(chatContext.chatHistory);
    }
  }, [chatContext?.chatHistory]);

  const handleFollowUpQuestion = (value: string) => {
    chatContext?.saveChat({ question: value, history: "" });
  };

  useEffect(() => {
    if (dataToDisplay && dataToDisplay.length > 0) {
      if (latestAnswerRef.current !== null) {
        latestAnswerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [loading, dataToDisplay, chatContext?.activeIndex]);

  const handleCitationChange = (value: string) => {
    if (value.length > 0) {
      if (value === documentToShow) {
        setDocumentToShow("");
      } else {
        setDocumentToShow("");
        setDocumentToShow(value);
      }
    }
  };

  const handlePinConversation = (index: number) => {
    // console.log("Pin conversation");
    // console.log("index: ", index);
    // console.log(chatContext?.chatHistory);

    if (chatContext && chatContext.chatHistory) {
      chatContext.chatHistory[index].pin = !chatContext.chatHistory[index].pin;
      chatContext?.addPinnedConversation(chatContext.chatHistory, index);
    }
  };

  //TODO: move it to shared css styling file once the position of button gets confirmed
  const stopBtnStyle: CSS.Properties = {
    backgroundColor: "#fff",
    position: "fixed",
    bottom: "2%",
    left: "50%",
  };

  const saveFeedbackToDB = () => {
    const submitChatFeedbackResponse = submitChatFeedback(dataToDisplay);

    if (dataToDisplay.filter((item) => item.pin === true).length > 0) {
      const submitChatHistoryResponse = submitChatHistory({
        initialMessage: dataToDisplay[0].question,
        sessionId: dataToDisplay[0].sessionId,
        chatMessages: dataToDisplay,
      } as PinnedChat);
    }
  };

  return loading || imageLoading ? (
    <div className="h-full flex">
      <div className="flex flex-col m-auto w-4/5 pt-6 text-center">
        <div>
          <h4 className="text-red font-semibold">
            {chatContext?.chat.question}
          </h4>
        </div>
        <div className="m-auto">
          <img src={loadingimage} />
        </div>
        <div className="mb-6">Loading</div>
        <div>
          <StopRespondingButton
            abortControllerRef={[abortControllerRef, imageAbortControllerRef]}
          ></StopRespondingButton>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="flex flex-col w-full min-w-0">
        {chatContext &&
          chatContext?.chat.question.length > 0 &&
          !loading &&
          !imageLoading &&
          !streaming && (
            <ChatQuestionDock
              FollowUpQuestions={chatSuggestions}
              callback={handleFollowUpQuestion}
              abortControllerRef={[abortControllerRef, imageAbortControllerRef]}
              setQuestionId={setQuestionId}
            />
          )}
        <div className="mx-auto w-10/12 text-center pt-4 pb-2 border-b border-[#F2F2F2]">
          <div className="truncate">
            Home /{" "}
            {dataToDisplay.length > 0
              ? dataToDisplay[0].question
              : chatContext?.chat.question}
          </div>
        </div>
        <div className="mx-auto w-10/12 flex flex-row gap-4 min-w-0">
          <div className={documentToShow ? "w-1/2 flex" : "flex m-auto"}>
            <div className="flex flex-col pt-6 overflow-hidden m-auto">
              <div>
                <div className="grid grid-cols-1 gap-4">
                  {((!loading &&
                    !streaming &&
                    dataToDisplay.length < 1 &&
                    !abortControllerRef.current.signal.aborted &&
                    !imageAbortControllerRef.current.signal.aborted) ||
                    error) && (
                    <ErrorCard title="Something went wrong" error={error} />
                  )}

                  {!loading &&
                    !streaming &&
                    dataToDisplay.length < 1 &&
                    (abortControllerRef.current.signal.aborted ||
                      imageAbortControllerRef.current.signal.aborted) && (
                      <ErrorCard
                        title="Response stopped"
                        error="As requested, your response has been stopped."
                      />
                    )}
                  {dataToDisplay.map((item, i) => (
                    <div
                      key={item.question}
                      ref={
                        i === chatContext?.activeIndex ? latestAnswerRef : null
                      }
                    >
                      <ChatQuestionCard
                        chatMessage={item}
                        streamResponse={null}
                        streaming={streaming}
                        callback={handleCitationChange}
                        pinnedConversationCallback={handlePinConversation}
                        saveFeedbackCallback={saveFeedbackToDB}
                        index={i}
                        images={item.images}
                      />
                      <div className="pb-2">
                        <WSPRule />
                      </div>
                    </div>
                  ))}

                  {streamResponse && streaming && (
                    <>
                      <ChatQuestionCard
                        chatMessage={
                          {
                            question: chatContext
                              ? chatContext?.chat.question
                              : "",
                            output: "",
                            indexes: chatContext ? chatContext?.indexes : [],
                            pin: false,
                          } as AIChatResponseHistory
                        }
                        streamResponse={streamResponse}
                        streaming={streaming}
                        callback={handleCitationChange}
                        pinnedConversationCallback={handlePinConversation}
                        saveFeedbackCallback={saveFeedbackToDB}
                        index={0}
                        images={null}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {documentToShow && (
            <div className="flex w-1/2">
              <div className="flex flex-col bg-light-gray fixed h-[94vh] w-2/6">
                <div className="ml-auto">
                  <button
                    type="button"
                    className="p-2"
                    onClick={() => setDocumentToShow("")}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.8327 1.34163L10.6577 0.166626L5.99935 4.82496L1.34102 0.166626L0.166016 1.34163L4.82435 5.99996L0.166016 10.6583L1.34102 11.8333L5.99935 7.17496L10.6577 11.8333L11.8327 10.6583L7.17435 5.99996L11.8327 1.34163Z"
                        fill="#3D5F7B"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={
                    documentToShow.includes("pdf")
                      ? "border w-full h-full"
                      : "border w-full"
                  }
                >
                  <DocumentViewer blobName={documentToShow} />
                </div>
              </div>
            </div>
          )}
        </div>
        {streaming && (
          <div style={stopBtnStyle}>
            <StopRespondingButton
              abortControllerRef={[abortControllerRef, imageAbortControllerRef]}
            ></StopRespondingButton>
          </div>
        )}
      </div>
    </>
  );
};

export default Chat;
