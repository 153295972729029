import { CitationType } from "../../enums/CitationTypes";

interface CitationCardProps {
  citationType: CitationType | undefined;
  citation: string;
  docPath: string | null;
  displayText: string;
  index: string;
  callback: (value: string) => void;
}

const CitationCard: React.FC<CitationCardProps> = ({
  citationType,
  citation,
  docPath,
  displayText,
  index,
  callback,
}) => {
  const isImage = (filename: string) => {
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "tiff",
      "webp",
    ];
    const extension = filename?.split(".")?.pop()?.toLowerCase();
    return imageExtensions.includes(extension!);
  };

  const citationURL = (value: string) => {
    let returnURL = citation;

    const regex = RegExp(/href="([^<]+)"/, "gi");

    let m;

    do {
      m = regex.exec(returnURL);

      if (m) {
        returnURL = m[0].replace('href="', "").replace('"', "");
      }
    } while (m);

    returnURL = returnURL.replace("source:", "").trim();

    const isFileImage = isImage(returnURL);
    const svg = isFileImage ? <ImageSVG /> : <DocumentSVG />;

    return { returnURL, svg };
  };

  const ImageSVG = () => (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 4.66667V16.3333H4.16667V4.66667H15.8333ZM15.8333 3H4.16667C3.25 3 2.5 3.75 2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V4.66667C17.5 3.75 16.75 3 15.8333 3ZM11.7833 10.3833L9.28333 13.6083L7.5 11.45L5 14.6667H15L11.7833 10.3833Z"
        fill="#3D5F7B"
      />
    </svg>
  );

  const DocumentSVG = () => (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1673 13.3333H2.50065V1.66667H8.33398V0H2.50065C1.57565 0 0.833984 0.75 0.833984 1.66667V13.3333C0.833984 14.25 1.57565 15 2.50065 15H14.1673C15.084 15 15.834 14.25 15.834 13.3333V7.5H14.1673V13.3333ZM10.0007 0V1.66667H12.9923L4.80065 9.85833L5.97565 11.0333L14.1673 2.84167V5.83333H15.834V0H10.0007Z"
        fill="#3D5F7B"
      />
    </svg>
  );

  const { returnURL, svg } = citationURL(citation);

  const extractDocumentType = (value: string) => {
    const pathItems = value.split("/");

    const detectedFolderName: string = pathItems[3];

    switch (detectedFolderName) {
      case "007_WSP Deliverables":
        return "Internal";
      default:
        return "External";
    }
  };

  const extractDocumentCategory = (value: string) => {
    const pathItems = value.split("/");

    const detectedFolderName: string = pathItems[3];

    switch (detectedFolderName) {
      case "007_WSP Deliverables":
        return "WSP Documents";
      case "006_Conferences":
        return "Conference";
      default:
        return "Mining KB";
    }
  };

  return (
    <div className="bg-light-blue p-6 whitespace-nowrap flex flex-col gap-2 my-auto min-w-0" key={citation}>
      <div>
        <div className="flex flex-row gap-2 min-w-0">
          <div className="grow min-w-0">
            <button
              type="button"
              className="w-full truncate flex flew-row gap-2 m-auto"
              onClick={() => callback(returnURL)}
            >
              {citationType === CitationType.Document && (
                <div className="text-blue font-medium">{index}</div>
              )}
              <div className="text-[#3D5F7B] font-semibold underline truncate">
                {displayText}
              </div>
            </button>
          </div>
          <div className="m-auto">
            <a
              href={`/citation?path=${returnURL}`}
              target="_blank"
              rel="noreferrer"
              className="text-[#3D5F7B] font-semibold underline"
            >
              {svg}
            </a>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-row gap-2">
          <div className="m-auto">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.87996 13.5433H1.87996C1.14663 13.5433 0.546631 12.9433 0.546631 12.21V1.54329C0.546631 0.809961 1.14663 0.209961 1.87996 0.209961H7.2133L11.2133 4.20996V8.20996H9.87996V4.87663H6.54663V1.54329H1.87996V12.21H7.87996V13.5433ZM10.5466 13.3166V11.8233L12.5133 13.79L13.4533 12.85L11.4866 10.8766H12.98V9.54329H9.2133V13.3166H10.5466Z"
                fill="#6C6E74"
              />
            </svg>
          </div>
          <div className="grow">
            {extractDocumentCategory(docPath ? docPath : citation)}
          </div>
          <div className="m-auto">
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.11337 1.99996L6.44671 3.33329H12.3334V9.99996H1.66671V1.99996H5.11337ZM5.66671 0.666626H1.66671C0.933374 0.666626 0.340041 1.26663 0.340041 1.99996L0.333374 9.99996C0.333374 10.7333 0.933374 11.3333 1.66671 11.3333H12.3334C13.0667 11.3333 13.6667 10.7333 13.6667 9.99996V3.33329C13.6667 2.59996 13.0667 1.99996 12.3334 1.99996H7.00004L5.66671 0.666626Z"
                fill="#6C6E74"
              />
            </svg>
          </div>
          <div className="grow">{extractDocumentType(docPath ? docPath : citation)}</div>
        </div>
      </div>
    </div>
  );
};

export default CitationCard;
