import { ReactNode } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

type Props = {
    children: ReactNode;
};

export const AADAuthenticator = ({ children }: Props) => {
    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>{children}</MsalAuthenticationTemplate>
        </MsalProvider>
    );
};
