import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useGetAIResponse from "../../hooks/getAIResponse";
import { AIResponse } from "../../models/airesponse";


const defaultUserRequest = `[
    {
        "role": "user",
        "content": "What is rock bursting?"
    }
  ]`;

const TestPage = () => {
  const { t, i18n } = useTranslation();
  const [submitReady, setSubmitReady] = useState<boolean>(true);
  const [userRequest, setUserRequest] = useState<string>(defaultUserRequest);
  const [messageToSend, setMessageToSend] = useState<string>("");
  const [aiResponse, setAiResponse] = useState<string>("");
  const [aiCitations, setaiCitations] = useState<string[]>([]);
  const { data, loading, error } = useGetAIResponse<AIResponse>(messageToSend);

  useEffect(() => {
    if (data) {
      setAiResponse(data?.assistant);
      setaiCitations(data?.citations);
    }
  }, [data]);

  const handleUserChange = (value: string) => {
    setUserRequest(value);
    if (value.length > 0) {
      setSubmitReady(true);
    } else {
      setSubmitReady(false);
    }
  };

  const handleSubmit = () => {
    setMessageToSend(userRequest);
  };

  return (
    <div className="container mx-auto">
      <div>
        <h1>{t("Welcome to Mining AI")}</h1>
      </div>
      <div>Request</div>
      <div>
        <textarea
          rows={10}
          cols={50}
          className="border-solid border-2 border-blue rounded-md p-2 w-[100%]"
          value={userRequest}
          onChange={(e) => handleUserChange(e.target.value)}
        ></textarea>
      </div>
      <div className="text-right text-white">
        <button
          type="button"
          disabled={!submitReady}
          className={
            submitReady
              ? `bg-red hover:bg-[#BE2100] active:bg-[#FA5E3D] text-[#FFFFFF] border-0 p-2`
              : "bg-[#d3d3d3] text-[#FFFFFF] border-0 p-2"
          }
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      <div className="text-center">
        {loading ? "Loading..." : " "}&nbsp;{error}
      </div>
      <div>Response</div>
      <div>
        <textarea
          rows={5}
          cols={50}
          wrap="hard"
          className="border-solid border-2 border-blue rounded-md p-2 w-[100%]"
          value={aiResponse}
        ></textarea>
      </div>
      <div>Citations</div>
      <div>
        <textarea
          rows={40}
          cols={50}
          wrap="hard"
          className="border-solid border-2 border-blue rounded-md p-2 w-[100%]"
          value={JSON.stringify(aiCitations)}
        ></textarea>
      </div>
    </div>
  );
};

export default TestPage;
