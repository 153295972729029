import { useState } from "react";
import { ChatContextType, iChatContext } from "./ChatContextType";
import React from "react";
import { base } from "../../data";
import { v4 as uuidv4 } from "uuid";
import { AIChatResponse } from "../../models/aichatresponse";
import { FilterScope } from "../../enums/FilterScope";
import { SearchType } from "../../enums/SearchTypes";
import { AIChatResponseHistory } from "../../models/aichatresponsehistory";
import { PinnedChat } from "../../models/pinnedchat";
import useSubmitChatHistory from "../../services/submitChatHistory";
import { PersistChatResponse } from "../../models/persistchatresponse";
import submitChatHistory from "../../services/submitChatHistory";

export const ChatContext = React.createContext<ChatContextType | null>(null);

const ChatContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sessionId, setSessionId] = useState<string>(
    uuidv4().replaceAll("-", "")
  );
  const [chat, setChat] = useState<iChatContext>({
    question: "",
    history: "",
  });
  const [indexes, setIndexes] = useState<string[]>(
    base.map((item) => item.indexName)
  );
  const [allSelected, setAllSelected] = useState<boolean>(true);
  const [pinnedConversations, setPinndedConversations] = useState<PinnedChat[]>(
    []
  );
  const [activePinnedConversation, setActivePinnedConversation] =
    useState<AIChatResponseHistory | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [filterScope, setFilterScope] = useState<FilterScope>(
    FilterScope.DocumentsAndImages
  );
  const [searchType, setSearchType] = useState<SearchType>(SearchType.All);
  const [chatHistory, setChatHistory] = useState<AIChatResponseHistory[]>([]);
  const [chatHistoryUpdateTimestamp, setChatHistoryUpdateTimestamp] =
    useState<string>("");

  const saveChat = (newValues: iChatContext) => {
    setChat(newValues);
  };

  const saveIndexes = (newIndexes: string[]) => {
    setIndexes(newIndexes);
  };

  const saveAllSelected = (value: boolean) => {
    setAllSelected(value);
  };

  const saveChatAndIndexes = (
    chat: iChatContext,
    newIndexes: string[],
    filterScope?: FilterScope
  ) => {
    setChat(chat);
    setIndexes(newIndexes);
    if (filterScope) {
      setFilterScope(filterScope);
    }
  };

  const startNewSession = () => {
    setSessionId(uuidv4().replaceAll("-", ""));
    setActivePinnedConversation(null);
    setChatHistory([]);
  };

  // add the conversation to the list of pinned conversations
  const addPinnedConversation = (
    conversation: AIChatResponseHistory[],
    activeIndex: number
  ) => {
    // console.log("Add conversation to pinned conversations");
    // console.log(conversation);

    let newPinnedChat: PinnedChat = {
      initialMessage: conversation[0].question,
      sessionId: conversation[0].sessionId,
      chatMessages: conversation,
    } as PinnedChat;

    let newPinnedConversations = [...pinnedConversations];

    const existingItemIndex = newPinnedConversations.findIndex(
      (item) => item.sessionId === newPinnedChat.sessionId
    );

    if (existingItemIndex > -1) {
      newPinnedConversations.splice(existingItemIndex, 1);
    }

    newPinnedConversations.push(newPinnedChat);

    setPinndedConversations(newPinnedConversations);

    const submitChatHistoryResponse = submitChatHistory(newPinnedChat);

    if (submitChatHistoryResponse) {
      setTimeout(() => {
        setChatHistoryUpdateTimestamp(Date.now().toString());  
      }, 500);
      
    }
  };

  // change the active pinned conversation to a different conversaation
  const saveActivePinnedConversation = (
    conversation: PinnedChat,
    activeIndex: number
  ) => {
    // console.log("Change active pinned convesaton");
    // console.log("index: ", activeIndex);
    // console.log(conversation);

    setChatHistory(conversation.chatMessages);
    setActiveIndex(activeIndex);
  };

  const saveFilterScope = (scope: FilterScope) => {
    setFilterScope(scope);
  };

  const saveSearchType = (searchType: SearchType) => {
    setSearchType(searchType);
  };

  const addToChatHistory = (historyItem: AIChatResponseHistory) => {
    let newChatHistory = [...chatHistory];

    const existingItemIndex = newChatHistory.findIndex(
      (item) => item.questionId === historyItem.questionId
    );

    if (existingItemIndex > -1) {
      newChatHistory.splice(existingItemIndex, 1);
    }

    newChatHistory.push(historyItem);

    setChatHistory(newChatHistory);
    setActiveIndex(newChatHistory.length - 1);
  };

  const saveChatHistoryUpdateTimestamp = (value: string) => {
    setChatHistoryUpdateTimestamp(value);
  };

  return (
    <ChatContext.Provider
      value={{
        sessionId,
        chat,
        saveChat,
        indexes,
        saveIndexes,
        allSelected,
        saveAllSelected,
        saveChatAndIndexes,
        startNewSession,
        activePinnedConversation,
        saveActivePinnedConversation,
        pinnedConversations,
        addPinnedConversation,
        activeIndex,
        filterScope,
        saveFilterScope,
        searchType,
        saveSearchType,
        chatHistory,
        addToChatHistory,
        chatHistoryUpdateTimestamp,
        saveChatHistoryUpdateTimestamp,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
