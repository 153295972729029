export const mine_sites = [
  {
    displayText: "Twin Metals",
    indexName: "mine_twin_metals",
  },
  {
    displayText: "Tamarack",
    indexName: "mine_tamarack",
  },
  {
    displayText: "Red Chris",
    indexName: "mine_red_chris",
  },
  {
    displayText: "Oyu Tolgoi",
    indexName: "mine_oyu_tolgoi",
  },
  {
    displayText: "North Mine",
    indexName: "mine_north_mine",
  },
  {
    displayText: "Merdeka Copper",
    indexName: "mine_merdeka_copper",
  },
  {
    displayText: "Lkab",
    indexName: "mine_lkab",
  },
  {
    displayText: "Kazinc",
    indexName: "mine_kazinc",
  },
  {
    displayText: "Jwaneng",
    indexName: "mine_jwaneng",
  },
  {
    displayText: "Hu U",
    indexName: "mine_hu_u",
  },
  {
    displayText: "Horne 5",
    indexName: "mine_horne_5",
  },
  {
    displayText: "Holt",
    indexName: "mine_holt",
  },
  {
    displayText: "Grasberg",
    indexName: "mine_grasberg",
  },
  {
    displayText: "Garson",
    indexName: "mine_garson",
  },
  {
    displayText: "Erg",
    indexName: "mine_erg",
  },
  {
    displayText: "East Malartic",
    indexName: "mine_east_malartic",
  },
  {
    displayText: "Eagle East",
    indexName: "mine_eagle_east",
  },
  {
    displayText: "Creighton",
    indexName: "mine_creighton",
  },
  {
    displayText: "Coleman",
    indexName: "mine_coleman",
  },
  {
    displayText: "Codelco Chuquicamata",
    indexName: "mine_codelco_chuquicamata",
  },
  {
    displayText: "Carrapateena",
    indexName: "mine_carrapateena",
  },
  {
    displayText: "Caraiba",
    indexName: "mine_caraiba",
  },
  {
    displayText: "Cadia",
    indexName: "mine_cadia",
  },
  {
    displayText: "Manuals",
    indexName: "manuals",
  },
  {
    displayText: "Papers",
    indexName: "papers",
  },
  {
    displayText: "Guidance Documents",
    indexName: "guidance_documents",
  },
  {
    displayText: "Hermosa",
    indexName: "mine_hermosa",
  },
];
