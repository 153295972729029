import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { WSPRule } from "../components";
import { AADSignoutButton } from "../components";
import { ChatContextType } from "../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../contexts/ChatContext/ChatContextProvider";
import { base } from "../data";
import { AIChatResponse } from "../models/aichatresponse";
import { FilterScope } from "../enums/FilterScope";
import { AIChatResponseHistory } from "../models/aichatresponsehistory";
import { PinnedChat } from "../models/pinnedchat";
import useGetPinnedChats from "../hooks/getPinnedChats";

const CommonLayout = () => {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const navigate = useNavigate();
  const [pinnedChatSessionsData, setPinnedChatSessionsData] =
    useState<Partial<Record<string, AIChatResponseHistory[]>>>();

  const { pinnedChatData } = useGetPinnedChats(
    chatContext ? chatContext.chatHistoryUpdateTimestamp : ""
  );

  useEffect(() => {
    if (pinnedChatData && pinnedChatData.length > 0) {
      const pinnedChatSessions: Partial<
        Record<string, AIChatResponseHistory[]>
      > = Object.groupBy(
        pinnedChatData,
        (item: AIChatResponseHistory) => item.sessionId + "|" + item.timestamp
      );

      if (pinnedChatSessions !== undefined) {
        setPinnedChatSessionsData(pinnedChatSessions);
      }
    }
  }, [pinnedChatData]);

  const handleNewThread = () => {
    chatContext?.saveChatAndIndexes(
      { question: "", history: "" },
      base.map((element) => element.indexName),
      FilterScope.DocumentsAndImages
    );
    chatContext?.saveAllSelected(true);
    chatContext?.startNewSession();
    navigate("/");
  };

  return (
    <div className="container mx-auto min-h-screen">
      <div className="flex flex-row min-h-screen min-w-0">
        <div className="flex-none bg-light-gray-50 max-w-60">
          <div className="flex flex-col h-screen mx-4 sticky top-0">
            <div className="flex">
              <div className="flex-auto ">
                <div className="max-w-40 pb-2 pt-4">
                  <a title="WSP" href="/">
                    <svg
                      aria-labelledby="title"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-red w-[85px]"
                      viewBox="0 0 57.372 27.285"
                    >
                      <title id="title" lang="en">
                        WSP
                      </title>
                      <defs></defs>
                      <path
                        d="M85.453,25.95a11.223,11.223,0,0,0-2.613-7.373h4.3a12.35,12.35,0,0,1,2.283,7.373v.011a12.358,12.358,0,0,1-2.291,7.385h-4.3a11.218,11.218,0,0,0,2.621-7.385Z"
                        transform="translate(-32.048 -18.577)"
                      ></path>
                      <path
                        d="M3.968,0H0L5.445,14.766H7.554l.929-2.52Z"
                        transform="translate(0 0)"
                      ></path>
                      <path
                        d="M17.419,0H13.451L18.9,14.766H21l.929-2.52Z"
                        transform="translate(-2.807 0)"
                      ></path>
                      <path
                        d="M55.761,27.285V0h-3.8V27.285Z"
                        transform="translate(-10.843 0)"
                      ></path>
                      <path
                        d="M47.912,20.767a6.507,6.507,0,0,1,.512-2.19H51.9a3.188,3.188,0,0,0-.252,2.429,3.638,3.638,0,0,0,1.622,1.924,23.1,23.1,0,0,0,2.871,1.331,11.618,11.618,0,0,1,2.8,1.673A5.551,5.551,0,0,1,61,29.842c.015.192.021.385.021.579a6.516,6.516,0,0,1-.61,2.92H56.846a3.977,3.977,0,0,0,.577-2.16c-.125-1.86-2.4-2.878-4.3-3.723-.29-.134-.575-.255-.847-.383a10.864,10.864,0,0,1-2.37-1.433,5.031,5.031,0,0,1-1.641-2.124,6.146,6.146,0,0,1-.372-2.485C47.9,20.944,47.9,20.855,47.912,20.767Z"
                        transform="translate(-24.756 -18.577)"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="pb-2 text-red uppercase">
                  <h6>The Foundation Model</h6>
                </div>
              </div>
            </div>
            <div className="pb-2">
              <WSPRule />
            </div>
            <div>
              <button
                type="button"
                className="bg-blue text-white w-full py-2"
                onClick={handleNewThread}
              >
                New Thread
              </button>
            </div>
            <div className="py-2">
              <h6>Threads</h6>
            </div>
            <div className="pb-2 flex-1 overflow-y-auto">
              <div className="flex flex-col gap-2 h-full">
                <div className="text-sm flex-1">
                  {pinnedChatSessionsData &&
                    Object.entries(pinnedChatSessionsData).map((item) => {
                      return item.map((subItem) => {
                        if (typeof subItem === "object") {
                          return subItem.map((chatItem, index) => {
                            return (
                              (chatItem.pin ||
                                subItem.filter(
                                  (f) =>
                                    f.pin === true &&
                                    f.sessionId === chatItem.sessionId
                                ).length > 0) && (
                                <div>
                                  {index === 0 && (
                                    <button
                                      type="button"
                                      className="pt-4 pb-2 px-2 w-full text-left whitespace-nowrap overflow-hidden text-ellipsis"
                                      onClick={() => {
                                        chatContext?.saveActivePinnedConversation(
                                          {
                                            initialMessage: subItem[0].question,
                                            sessionId: subItem[0].sessionId,
                                            chatMessages: subItem,
                                          } as PinnedChat,
                                          0
                                        );
                                        navigate("/chat");
                                      }}
                                    >
                                      {chatItem.question}
                                    </button>
                                  )}
                                  {index > 0 && chatItem.pin && (
                                    <button
                                      type="button"
                                      className="text-left w-full"
                                      onClick={() => {
                                        chatContext?.saveActivePinnedConversation(
                                          {
                                            initialMessage: subItem[0].question,
                                            sessionId: subItem[0].sessionId,
                                            chatMessages: subItem,
                                          } as PinnedChat,
                                          index
                                        );
                                        navigate("/chat");
                                      }}
                                    >
                                      <div className="flex flex-row gap-2 py-2 px-2">
                                        <div className="m-auto w-3">
                                          {chatItem.pin && (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="stroke-current stroke-0 m-auto text-blue"
                                              width="10"
                                              height="14"
                                              fill="currentColor"
                                              viewBox="0 0 10 14"
                                              stroke="currentColor"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1"
                                                d="M6.33398 1.66667V5C6.33398 5.74667 6.58065 6.44 7.00065 7H3.00065C3.43398 6.42667 3.66732 5.73334 3.66732 5V1.66667H6.33398ZM8.33398 0.333336H1.66732C1.30065 0.333336 1.00065 0.633336 1.00065 1C1.00065 1.36667 1.30065 1.66667 1.66732 1.66667H2.33398V5C2.33398 6.10667 1.44065 7 0.333984 7V8.33334H4.31398V13L4.98065 13.6667L5.64732 13V8.33334H9.66732V7C8.56065 7 7.66732 6.10667 7.66732 5V1.66667H8.33398C8.70065 1.66667 9.00065 1.36667 9.00065 1C9.00065 0.633336 8.70065 0.333336 8.33398 0.333336Z"
                                              />
                                            </svg>
                                          )}
                                        </div>
                                        <div className="grow m-auto whitespace-nowrap overflow-hidden text-ellipsis">
                                          {chatItem.question}
                                        </div>
                                      </div>
                                    </button>
                                  )}
                                </div>
                              )
                            );
                          });
                        }
                      });
                    })}
                </div>
              </div>
            </div>
            <div className="pb-2">
              <WSPRule />
            </div>
            <div className="mb-4">
              <AADSignoutButton />
            </div>
          </div>
        </div>
        <div className="grow min-w-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
